export const footerItems = [
  {
    language: 'en',
    textList: {
      t1: 'All rights reserved'
    }
  },
  {
    language: 'gd',
    textList: {
      t1: 'Còraichean uile glèidhte'
    }
  },
  {
    language: 'cy',
    textList: {
      t1: 'Cedwir pob hawl'
    }
  },
  {
    language: 'ga',
    textList: {
      t1: 'Gach ceart ar cosaint'
    }
  },
  {
    language: 'ar',
    textList: {
      t1: 'كل الحقوق محفوظة'
    }
  },
  {
    language: 'bn',
    textList: {
      t1: 'সমস্ত অধিকার সংরক্ষিত'
    }
  },
  {
    language: 'bg',
    textList: {
      t1: 'Всички права запазени'
    }
  },
  {
    language: 'nl',
    textList: {
      t1: 'Alle rechten voorbehouden'
    }
  },
  {
    language: 'tl',
    textList: {
      t1: 'Nakalaan ang lahat ng mga karapatan'
    }
  },
  {
    language: 'fr',
    textList: {
      t1: 'Tous les droits sont réservés'
    }
  },
  {
    language: 'gr',
    textList: {
      t1: 'Alle Rechte vorbehalten'
    }
  },
  {
    language: 'el',
    textList: {
      t1: 'Ολα τα δικαιώματα διατηρούνται'
    }
  },
  {
    language: 'hi',
    textList: {
      t1: 'सभी अधिकार सुरक्षित'
    }
  },
  {
    language: 'it',
    textList: {
      t1: 'Tutti i diritti riservati'
    }
  },
  {
    language: 'pl',
    textList: {
      t1: 'Wszelkie prawa zastrzeżone'
    }
  },
  {
    language: 'pt',
    textList: {
      t1: 'Todos os direitos reservados'
    }
  },
  {
    language: 'ro',
    textList: {
      t1: 'Toate drepturile rezervate'
    }
  },
  {
    language: 'ru',
    textList: {
      t1: 'Все права защищены'
    }
  },
  {
    language: 'es',
    textList: {
      t1: 'Todos los derechos reservados'
    }
  },
  {
    language: 'tr',
    textList: {
      t1: 'Her hakkı saklıdır'
    }
  },
  {
    language: 'ur',
    textList: {
      t1: 'جملہ حقوق محفوظ ہیں'
    }
  }
]