// React libraries
import React, { useState, useEffect, useRef } from 'react';
// External libraries
// My links
import BigBofText from '../components/BigBofText';
import './header.css';
import {counterItems} from './HeaderText';
import UseFetchText from '../hooks/useFetchText';

function Header({ selectedLanguage }) {
  if (!selectedLanguage) selectedLanguage = {label: "English", local: "English", value: "en"};

  //const [showMenu, setShowMenu] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const daysRef = useRef();
  const hrsRef = useRef();
  const mnsRef = useRef();
  const scsRef = useRef();

  useEffect(() => {
    const days = new Date().getUTCDay() === 0 ? new Date().getUTCDay() : 7 - new Date().getUTCDay()
    const hours = 23 - new Date().getUTCHours();
    const minutes = 59 - new Date().getUTCMinutes();
    const seconds = 59 - new Date().getUTCSeconds();

    const timerId = setTimeout(() => {
      setTimeRemaining({days: days, hours: hours, minutes: minutes, seconds: seconds});
      
    }, 1000);

    return () => {clearTimeout(timerId)};
  }
  , [timeRemaining]);

  const toggleShowMenut = () => { 
    const barsState = document.querySelector('.bars');
    const barsDisplay = getComputedStyle(barsState).display;

    if (barsDisplay === 'block'){
      document.querySelector('.menuDiv').style.display = "block";
      document.querySelector('.mainBodyDiv').style.display = "none";
      document.querySelector('.bars').style.display = "none";
      document.querySelector('.times').style.display = "block";
    }
    else {
      document.querySelector('.menuDiv').style.display = "none";
      document.querySelector('.mainBodyDiv').style.display = "block";
      document.querySelector('.bars').style.display = "block";
      document.querySelector('.times').style.display = "none";
    }
  };

  return (
    <div className="headerFull" >
      <div className="divBigBofContainer" >
        <div className="divBigBofText"><BigBofText com={true}/></div>
      </div>
      <div className="divCounterFull" >
        <div className="divRemainingContainer">
          <div className="divCounterTitle">{UseFetchText(selectedLanguage, counterItems, 't1')}</div>
          <div className="divCounters">
            <div className="divCounterMain">
              <div ref={daysRef} className="divCounterBottom">{timeRemaining.days}</div>
            </div>
            <div className="divCounterMain">
              <div ref={hrsRef} className="divCounterBottom">{timeRemaining.hours < 10 ? '0' + timeRemaining.hours: timeRemaining.hours}</div>
            </div>
            <div className="divCounterMain">
              <div ref={mnsRef} className="divCounterBottom">{timeRemaining.minutes < 10 ? '0' + timeRemaining.minutes: timeRemaining.minutes}</div>
            </div>
            <div className="divCounterMain">
              <div ref={scsRef} className="divCounterBottom">{timeRemaining.seconds < 10 ? '0' + timeRemaining.seconds: timeRemaining.seconds}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="divBarsIcon">
        <i className='bars icon' onClick={toggleShowMenut}></i>
        <i className='times icon' onClick={toggleShowMenut}></i>
      </div>
    </div>
  );
}

export default Header;
