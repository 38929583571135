// React libraries
import React, { useEffect, useRef } from 'react';
// External libraries
// My links
import {menuItems} from '../../menu/MenuText';
import UseFetchText from '../../hooks/useFetchText';

const PrivacyPolicy = ({ selectedLanguage }) => {
    const titleRef = useRef(null);

    useEffect(() => {
        titleRef.current.focus();
        titleRef.current.blur();
    }, [titleRef]);
    
    return (
        <div>
            <h1 ref={titleRef} tabIndex="-1">{UseFetchText(selectedLanguage, menuItems, 't11')}</h1>
            
            <p>

                1.	Our privacy policy describes the ways in which we collect, store, use and protect your personal information and it is important for you to review this privacy policy.

                By "personal information" we mean information that can be associated with a specific person and can be used to identify that person.<br /><br />

                2.	We do not consider anonymised information to constitute personal information as it cannot be used to identify a specific person. We collect personal information from you when you use bigBOF application (including, without limitation, when you register with us, send messages to us, or contact us or any of our team memberss for any reason).

                By providing us with your personal information you expressly consent to us processing your personal information in accordance with the terms of our privacy policy.<br /><br />

                3.	We may amend our privacy policy at any time by posting a revised version on the Site.

                The revised version will be effective at the time we post it and, following such posting, your continued use of the Site will constitute your express consent to us continuing to process your personal information in accordance with the terms of our revised privacy policy.<br /><br />

                4.	We would encourage you to check the Site regularly for the announcement of any amendments to our privacy policy.<br /><br />

                5.	Deleting your account and all your information<br /><br />

            </p>



            <h2>Our privacy policy covers the following topics:</h2>

            <p>

            1.	our collection of your personal information.<br /><br />

            2.	our use of your personal information.<br /><br />

            3.	accessing, reviewing and amending your personal information.<br /><br />

            4.	no spam, marketing, promotional or spoof emails.<br /><br />

            5.	protecting your personal information.<br /><br />

            </p>

            <h2>Our collection of your personal information</h2>

            <p>

            1.	As part of your registration, or at a later stage if you update the information under your profile, you will be asked to provide us with certain your email address .<br /><br />

            2.	We will NOT share, sell, or make accessible, your information to any third party for any reason.<br /><br />

            6.	We may also collect information about how you use our application for analitical purposes only.<br /><br />

            </p>

            <h2>Our use of your personal information</h2>

            <p>

            1.	We only use your personal information to provide services and customer support to you; to measure and improve our services to you; to prevent illegal activities; troubleshoot problems.<br /><br />

            2.	Though we make every effort to preserve your privacy, we may need to disclose your personal information to law enforcement agencies, government agencies or other third parties where we are compelled so to do by court order or similar legal procedure; where we are required to disclose your personal information to comply with law; where we are cooperating with an ongoing law enforcement investigation or where we have a good faith belief that our disclosure of your personal information is necessary to prevent physical harm or financial loss, to report suspected illegal activity or to investigate a possible violation of our Terms & Conditions.<br /><br />

            3.	In the event of a sale of bigBOF, any of its affiliates and subsidiaries or any related business assets, your personal information may be disclosed to any potential purchaser for the purposes of the continued provision of the service or otherwise in relation to any such sale.<br /><br />

            4.	We do not sell or rent any of your personal information to third parties.<br /><br />

            5.	By creating a bigBOF account, you give us your express consent to receive emails about our services and emails announcing changes to, and new features to our service, or confirming activities you carried out on, the app, or other reasons that form part of playing our game.<br /><br />
            </p>

            <h2>Accessing, reviewing and amending your personal information</h2>

            <p>

            1.	You can access and review your personal information in the Profile section of the application.

            2.	Please note that we shall retain your personal information during and following the end of your use of the service as required to comply with law, for technical troubleshooting requirements, to prevent fraud, to assist in any legal investigations and to take any other actions otherwise permitted by law.<br /><br />

            </p>

            <h2>No spam, marketing/promotional or spoof emails</h2>

            <p>

            We do not tolerate spam. To report in bigBOF related spam or spoof emails, please forward the email to admin@bigbof.com.<br /><br />

            </p>

            <h2>Protecting your personal information</h2>

            <p>

            1.	By providing us with personal information, you consent to the transfer of your personal information to, and its storage on, the servers that host our data. We take every precaution to safeguard all your personal information from unauthorized access, use or disclosure.<br /><br />

            2.	However, the Internet is not a secure medium and we cannot guarantee the privacy of your personal information.<br /><br />

            3.	Choose your password carefully using unique numbers, letters and special characters.<br /><br />

            4.	Never share your email and password with anyone.<br /><br />

            5.	If you are concerned that your credentials have been compromised, please change your password immediately by logging onto the Profile section of the app or clicking the forgot password link in the login page.<br /><br />

            </p>

            <h2>Deleting your account and all your information</h2>

            <p>

            1.	To delete your account, please send an email to info@bigbof.com and allow up to 30 days for the action to be completed.<br /><br />

            2.	However, please keep in mind that this action is irreversable and you will lose all your permanent tickets accumulated over time in case you decide to rejoin us.<br /><br />

            </p>

            <h4>Last update: April 19th, 2022</h4><br />
        </div>
    )
};
PrivacyPolicy.defaultProps = {
    selectedLanguage: {
        label: 'English',
        Local: 'English',
        value: 'en'
    }
}

export default PrivacyPolicy;