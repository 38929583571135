export const languageOptions = [
  {
    label: 'English',
    local: 'English',
    value: 'en'
  },
  {
    label: 'Gaelic',
    local: 'Gàidhlig',
    value: 'gd'
  },
  {
    label: 'Welsh',
    local: 'Cymraeg',
    value: 'cy'
  },
  {
    label: 'Irish',
    local: 'Gaeilge',
    value: 'ga'
  },
  {
    label: 'Arabic',
    local: 'العربية',
    value: 'ar'
  },
  {
    label: 'Bengali',
    local: 'বাংলা',
    value: 'bn'
  },
  {
    label: 'Bulgarian',
    local: 'български',
    value: 'bg'
  },
  {
    label: 'Dutch',
    local: 'Nederlands',
    value: 'nl'
  },
  {
    label: 'Filipino',
    local: 'Pilipino',
    value: 'tl'
  },
  {
    label: 'French',
    local: 'Française',
    value: 'fr'
  },
  {
    label: 'German',
    local: 'Deutsche',
    value: 'gr'
  },
  {
    label: 'Greek',
    local: 'Ελληνικά',
    value: 'el'
  },
  {
    label: 'Hindi',
    local: 'हिंदी',
    value: 'hi'
  },
  {
    label: 'Italian',
    local: 'Italiano',
    value: 'it'
  },
  {
    label: 'Polish',
    local: 'Polskie',
    value: 'pl'
  },
  {
    label: 'Portuguese',
    local: 'Português',
    value: 'pt'
  },
  {
    label: 'Romanian',
    local: 'Română',
    value: 'ro'
  },
  {
    label: 'Russian',
    local: 'русский',
    value: 'ru'
  },
  {
    label: 'Spanish',
    local: 'Español',
    value: 'es'
  },
  {
    label: 'Turkish',
    local: 'Türk',
    value: 'tr'
  },
  {
    label: 'Urdu',
    local: 'اردو',
    value: 'ur'
  }
];