// React libraries
import React from 'react';
import ReactDOM from 'react-dom';
// External libraries
// My links
import {AppHeader, AppMenu, AppBody, AppFooter} from './App.js';

ReactDOM.render(
    <AppHeader />,
  document.querySelector('.rootHeader')
);

ReactDOM.render(
    <AppMenu />,
  document.querySelector('.rootMenu')
);

ReactDOM.render(
    <AppBody />,
  document.querySelector('.rootBody')
);

ReactDOM.render(
    <AppFooter />,
  document.querySelector('.rootFooter')
);