export const languagesList = [
  {
    language: 'en',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'ar',
    textList: {
      en: 'الإنجليزية',
      ar: 'العربية',
      bn: 'البنغالية',
      bg: 'البلغارية',
      nl: 'الهولندية',
      fr: 'الفلبينية',
      gr: 'الفرنسية',
      el: 'الألمنية',
      hi: 'اليونانية',
      it: 'الهندية',
      pl: 'الايطالية',
      pt: 'البولندية',
      ro: 'البرتغالية',
      ru: 'الرومانية',
      es: 'الروسية',
      tl: 'الإسبانية',
      tr: 'التركية',
      ur: 'الأوردية'
    }
  },
  {
    language: 'bn',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'bg',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'nl',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'fr',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'gr',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'el',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'hi',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'it',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'pl',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'pt',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'ro',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'ru',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'es',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'tl',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'tr',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  },
  {
    language: 'ur',
    textList: {
      en: 'English',
      ar: 'Arabic',
      bn: 'Bengali',
      bg: 'Bulgarian',
      nl: 'Dutch',
      fr: 'Filipino',
      gr: 'French',
      el: 'German',
      hi: 'Greek',
      it: 'Hindi',
      pl: 'Italian',
      pt: 'Polish',
      ro: 'Portuguese',
      ru: 'Romanian',
      es: 'Russian',
      tl: 'Spanish',
      tr: 'Turkish',
      ur: 'Urdu'
    }
  }
]

export const menuItems = [
  {
    language: 'en',
    textList: {
      t1: 'Home',
      t2: 'Login/Register',
      t3: 'Current tickets',
      t4: 'Previous tickets',
      t5: 'Friends',
      t6: 'Previous winners',
      t7: 'How to play',
      t8: 'Terms & Conditions',
      t9: 'Contact us',
      t10: 'Log out',
      t11: 'Privacy Protection Policy',
      t12: 'Policies',
      t13: 'Profile',
      t14: 'Previous results',
      t15: 'History'
    }
  },
  {
    language: 'gd',
    textList: {
      t1: 'Duilleag-dhachaigh',
      t2: 'Log a-steach / Clàr',
      t3: 'Tiogaidean gnàthach',
      t4: 'Tiogaidean roimhe',
      t5: 'Caraidean',
      t6: 'Buannaichean roimhe',
      t7: 'Mar a chluicheas tu',
      t8: 'Cumhachan is Cùmhnantan',
      t9: 'Cuir fios thugainn',
      t10: 'Log a-mach',
      t11: 'Poileasaidh Dìon Dìomhaireachd',
      t12: 'Poileasaidhean',
      t13: 'Pròifil',
      t14: 'Toraidhean roimhe',
      t15: 'Eachdraidh'
    }
  },
  {
    language: 'cy',
    textList: {
      t1: 'Tudalen hafan',
      t2: 'Mewngofnodi / Cofrestru',
      t3: 'Tocynnau cyfredol',
      t4: 'Tocynnau blaenorol',
      t5: 'Ffrindiau',
      t6: 'Enillwyr blaenorol',
      t7: 'Sut i chwarae',
      t8: 'Telerau ac Amodau',
      t9: 'Cysylltwch â ni',
      t10: 'Allgofnodi',
      t11: 'Polisi Diogelu Preifatrwydd',
      t12: 'Polisïau',
      t13: 'Proffil',
      t14: 'Canlyniadau blaenorol',
      t15: 'Hanes'
    }
  },
  {
    language: 'ga',
    textList: {
      t1: 'Leathanach baile',
      t2: 'Logáil Isteach / Cláraigh',
      t3: 'Ticéid reatha',
      t4: 'Ticéid roimhe seo',
      t5: 'Cairde',
      t6: 'Buaiteoirí roimhe seo',
      t7: 'Conas a imirt',
      t8: 'Téarmaí ⁊ Coinníollacha',
      t9: 'Glaoigh orainn',
      t10: 'Logáil Amach',
      t11: 'Beartas um Chosaint Príobháideachta',
      t12: 'Polasaithe',
      t13: 'Próifíl',
      t14: 'Torthaí roimhe seo',
      t15: 'Stair'
    }
  },
  {
    language: 'ar',
    textList: {
      t1: 'الصفحة الرئيسية',
      t2: 'دخول/تسجيل',
      t3: 'التذاكر الحالية',
      t4: 'التذاكر السابقة',
      t5: 'الأصدقاء',
      t6: 'الفائزون السابقون',
      t7: 'طريقة اللعب',
      t8: 'الأحكام والشروط',
      t9: 'اتصل بنا',
      t10: 'خروج',
      t11: 'سياسة حماية الخصوصية',
      t12: 'سياسات',
      t13: 'الملف الشخصي',
      t14: 'النتائج السابقة',
      t15: 'التاريخ'
    }
  },
  {
    language: 'bn',
    textList: {
      t1: 'হোম পৃষ্ঠা',
      t2: 'প্রবেশ / নিবন্ধন করুন',
      t3: 'বর্তমান টিকিট',
      t4: 'আগের টিকিট',
      t5: 'বন্ধুরা',
      t6: 'পূর্ববর্তী বিজয়ীরা',
      t7: 'বাজানোর পদ্ধতি',
      t8: 'শর্তাবলী',
      t9: 'যোগাযোগ করুন',
      t10: 'প্রস্থান',
      t11: 'গোপনীয়তা সুরক্ষা নীতি',
      t12: 'নীতিমালা',
      t13: 'প্রোফাইল',
      t14: 'পূর্ববর্তী ফলাফল',
      t15: 'ইতিহাস'
    }
  },
  {
    language: 'bg',
    textList: {
      t1: 'Начална страница',
      t2: 'Влезте / Регистрирайте се',
      t3: 'Текущи билети',
      t4: 'Предишни билети',
      t5: 'Приятелите',
      t6: 'Предишни победители',
      t7: 'Метод на възпроизвеждане',
      t8: 'Правила и условия',
      t9: 'Свържете се с нас',
      t10: 'изход',
      t11: 'Политика за защита на поверителността',
      t12: 'Политики',
      t13: 'Профил',
      t14: 'Предишни резултати',
      t15: 'История'
    }
  },
  {
    language: 'nl',
    textList: {
      t1: 'Startpagina',
      t2: 'Invoeren / registreren',
      t3: 'Huidige tickets',
      t4: 'Eerdere tickets',
      t5: 'Vrienden',
      t6: 'Eerdere winnaars',
      t7: 'Speelmethode',
      t8: 'voorwaarden',
      t9: 'Neem contact op',
      t10: 'Uitgang',
      t11: 'Beleid inzake privacybescherming',
      t12: 'Beleid',
      t13: 'Profiel',
      t14: 'Eerdere resultaten',
      t15: 'Geschiedenis'
    }
  },
  {
    language: 'tl',
    textList: {
      t1: 'Homepage',
      t2: 'Ipasok / Magrehistro',
      t3: 'Mga kasalukuyang ticket',
      t4: 'Mga nakaraang ticket',
      t5: 'Mga kaibigan',
      t6: 'Mga nagdaang nanalo',
      t7: 'Paraan ng paglalaro',
      t8: 'mga tuntunin at kundisyon',
      t9: 'Makipag-ugnayan sa amin',
      t10: 'labasan',
      t11: 'Patakaran sa Proteksyon sa Privacy',
      t12: 'Mga Patakaran',
      t13: 'Profile',
      t14: 'Mga nakaraang resulta',
      t15: 'Kasaysayan'
    }
  },
  {
    language: 'fr',
    textList: {
      t1: 'Page d\'accueil',
      t2: 'Entrer / S\'inscrire',
      t3: 'Billets actuels',
      t4: 'Billets précédents',
      t5: 'Les amis',
      t6: 'Gagnants précédents',
      t7: 'Méthode de jeu',
      t8: 'Termes et conditions',
      t9: 'Nous contacter',
      t10: 'sortie',
      t11: 'Politique de protection de la vie privée',
      t12: 'Stratégies',
      t13: 'Profil',
      t14: 'Résultats précédents',
      t15: 'Histoire'
    }
  },
  {
    language: 'gr',
    textList: {
      t1: 'Startseite',
      t2: 'Eingeben / Registrieren',
      t3: 'Aktuelle Tickets',
      t4: 'Vorherige Tickets',
      t5: 'Die Freunde',
      t6: 'Bisherige Gewinner',
      t7: 'Spielmethode',
      t8: 'Geschäftsbedingungen',
      t9: 'Kontaktiere uns',
      t10: 'Ausfahrt',
      t11: 'Datenschutzrichtlinie',
      t12: 'Richtlinien',
      t13: 'Profil',
      t14: 'Bisherige Ergebnisse',
      t15: 'Geschichte'
    }
  },
  {
    language: 'el',
    textList: {
      t1: 'Αρχική σελίδα',
      t2: 'Εισαγάγετε / Εγγραφείτε',
      t3: 'Τρέχοντα εισιτήρια',
      t4: 'Προηγούμενα εισιτήρια',
      t5: 'Οι φίλοι',
      t6: 'Προηγούμενοι νικητές',
      t7: 'Μέθοδος παιχνιδιού',
      t8: 'όροι και Προϋποθέσεις',
      t9: 'Επικοινωνήστε μαζί μας',
      t10: 'έξοδος',
      t11: 'Πολιτική προστασίας απορρήτου',
      t12: 'Πολιτικές',
      t13: 'Προφίλ',
      t14: 'Προηγούμενα αποτελέσματα',
      t15: 'Ιστορία'
    }
  },
  {
    language: 'hi',
    textList: {
      t1: 'मुखपृष्ठ',
      t2: 'दर्ज / पंजीकरण',
      t3: 'वर्तमान टिकट',
      t4: 'पिछले टिकट',
      t5: 'दोस्तों',
      t6: 'पिछले विजेता',
      t7: 'खेलने की विधि',
      t8: 'नियम और शर्तें',
      t9: 'संपर्क करें',
      t10: 'बाहर जाएं',
      t11: 'गोपनीयता संरक्षण नीति',
      t12: 'नीतियाँ',
      t13: 'प्रोफ़ाइल',
      t14: 'पिछले परिणाम',
      t15: 'इतिहास'
    }
  },
  {
    language: 'it',
    textList: {
      t1: 'Pagina iniziale',
      t2: 'Entra / Registrati',
      t3: 'Biglietti attuali',
      t4: 'Biglietti precedenti',
      t5: 'Gli amici',
      t6: 'Vincitori precedenti',
      t7: 'Metodo di gioco',
      t8: 'termini e condizioni',
      t9: 'Contattaci',
      t10: 'Uscita',
      t11: 'Politica di protezione della privacy',
      t12: 'Politiche',
      t13: 'Profilo',
      t14: 'Risultati precedenti',
      t15: 'Storia'
    }
  },
  {
    language: 'pl',
    textList: {
      t1: 'Strona główna',
      t2: 'Wejdź / Zarejestruj się',
      t3: 'Aktualne bilety',
      t4: 'Poprzednie bilety',
      t5: 'Przyjaciele',
      t6: 'Poprzedni zwycięzcy',
      t7: 'Metoda gry',
      t8: 'Regulamin',
      t9: 'Skontaktuj się z nami',
      t10: 'wyjście',
      t11: 'Polityka ochrony prywatności',
      t12: 'Zasady',
      t13: 'Profil',
      t14: 'Poprzednie wynikiv',
      t15: 'Historia'
    }
  },
  {
    language: 'pt',
    textList: {
      t1: 'Pagina inicial',
      t2: 'Entrar / Registrar',
      t3: 'Bilhetes atuais',
      t4: 'Ingressos anteriores',
      t5: 'Os amigos',
      t6: 'Vencedores anteriores',
      t7: 'Método de jogo',
      t8: 'termos e Condições',
      t9: 'Contate-Nos',
      t10: 'Saída',
      t11: 'Política de Proteção de Privacidade',
      t12: 'Políticos',
      t13: 'Perfil',
      t14: 'Resultados anteriores',
      t15: 'História'
    }
  },
  {
    language: 'ro',
    textList: {
      t1: 'Pagina principala',
      t2: 'Intrați / Înregistrați-vă',
      t3: 'Bilete curente',
      t4: 'Bilete anterioare',
      t5: 'Prietenii',
      t6: 'Câștigătorii anteriori',
      t7: 'Metoda de joc',
      t8: 'termeni si conditii',
      t9: 'Contactează-ne',
      t10: 'Ieșire',
      t11: 'Politica de protecție a confidențialității',
      t12: 'Politici',
      t13: 'Profil',
      t14: 'Rezultate anterioare',
      t15: 'Istorie'
    }
  },
  {
    language: 'ru',
    textList: {
      t1: 'Домашняя страница',
      t2: 'Войти / Зарегистрироваться',
      t3: 'Текущие билеты',
      t4: 'Предыдущие билеты',
      t5: 'Друзья',
      t6: 'Предыдущие победители',
      t7: 'Метод игры',
      t8: 'условия и положения',
      t9: 'Связаться с нами',
      t10: 'Выход',
      t11: 'Политика защиты конфиденциальности',
      t12: 'Политики',
      t13: 'Профиль',
      t14: 'Предыдущие результаты',
      t15: 'История'
    }
  },
  {
    language: 'es',
    textList: {
      t1: 'Página principal',
      t2: 'Entrar / Registrarse',
      t3: 'Entradas actuales',
      t4: 'Entradas anteriores',
      t5: 'Los amigos',
      t6: 'Ganadores anteriores',
      t7: 'Método de juego',
      t8: 'términos y Condiciones',
      t9: 'Contacta con nosotros',
      t10: 'salida',
      t11: 'Política de protección de privacidad',
      t12: 'Políticas',
      t13: 'Perfil',
      t14: 'Resultados anteriores',
      t15: 'Historia'
    }
  },
  {
    language: 'tr',
    textList: {
      t1: 'Anasayfa',
      t2: 'Giriş / Kayıt',
      t3: 'Güncel biletler',
      t4: 'Önceki biletler',
      t5: 'Arkadaşlar',
      t6: 'Önceki kazananlar',
      t7: 'Oyun yöntemi',
      t8: 'şartlar ve koşullar',
      t9: 'Bize Ulaşın',
      t10: 'çıkış',
      t11: 'Gizlilik Koruma Politikası',
      t12: 'Politikalar',
      t13: 'Profil',
      t14: 'Önceki sonuçlar',
      t15: 'Tarih'
    }
  },
  {
    language: 'ur',
    textList: {
      t1: 'ہوم پیج',
      t2: 'درج کریں / رجسٹر کریں',
      t3: 'موجودہ ٹکٹ',
      t4: 'پچھلے ٹکٹ',
      t5: 'دوست',
      t6: 'پچھلے فاتح',
      t7: 'کھیل کا طریقہ',
      t8: 'شرائط و ضوابط',
      t9: 'ہم سے رابطہ کریں',
      t10: 'باہر نکلیں',
      t11: 'پرائیویسی پروٹیکشن پالیسی',
      t12: 'پالیسیاں',
      t13: 'پروفائل',
      t14: 'پچھلے نتائج',
      t15: 'تاریخ'
    }
  }
]