// React libraries
import React from 'react';
// External libraries
// My links
// My links
import BigBofText from '../components/BigBofText';
import UseFetchText from '../hooks/useFetchText';
import {footerItems} from './FooterText'
import './footer.css';

function Footer({ selectedLanguage }) {
  return (
    <div>
        <p>{<BigBofText />}&nbsp; ©2021  - {UseFetchText(selectedLanguage, footerItems, 't1')}</p>
    </div>
  );
}
Footer.defaultProps = {
    selectedLanguage: {
        label: 'English',
        Local: 'English',
        value: 'en'
    }
}

export default Footer;