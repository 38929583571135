export const counterItems = [
  {
    language: 'en',
    textList: {
      t1: 'This week ends after'
    }
  },
  {
    language: 'gd',
    textList: {
      t1: 'Thig an t-seachdain seo gu crìch às deidh'
    }
  },
  {
    language: 'cy',
    textList: {
      t1: 'Daw\'r wythnos hon i ben ar ôl'
    }
  },
  {
    language: 'ga',
    textList: {
      t1: 'Críochnóidh an tseachtain seo tar éis'
    }
  },
  {
    language: 'ar',
    textList: {
      t1: 'ينتهي هذا الأسبوع بعد'
    }
  },
  {
    language: 'bn',
    textList: {
      t1: 'এই সপ্তাহ পরে শেষ হয়'
    }
  },
  {
    language: 'bg',
    textList: {
      t1: 'Тази седмица приключва след'
    }
  },
  {
    language: 'nl',
    textList: {
      t1: 'Deze week eindigt daarna'
    }
  },
  {
    language: 'tl',
    textList: {
      t1: 'Nagtatapos ang linggong ito pagkatapos'
    }
  },
  {
    language: 'fr',
    textList: {
      t1: 'Cette semaine se termine après'
    }
  },
  {
    language: 'gr',
    textList: {
      t1: 'Diese Woche endet danach'
    }
  },
  {
    language: 'el',
    textList: {
      t1: 'Αυτή η εβδομάδα τελειώνει μετά'
    }
  },
  {
    language: 'hi',
    textList: {
      t1: 'इस सप्ताह के बाद समाप्त होता है'
    }
  },
  {
    language: 'it',
    textList: {
      t1: 'Questa settimana finisce dopo'
    }
  },
  {
    language: 'pl',
    textList: {
      t1: 'Ten tydzień kończy się później'
    }
  },
  {
    language: 'pt',
    textList: {
      t1: 'Esta semana termina depois'
    }
  },
  {
    language: 'ro',
    textList: {
      t1: 'Săptămâna aceasta se încheie după'
    }
  },
  {
    language: 'ru',
    textList: {
      t1: 'Эта неделя заканчивается после'
    }
  },
  {
    language: 'es',
    textList: {
      t1: 'Esta semana termina después'
    }
  },
  {
    language: 'tr',
    textList: {
      t1: 'Bu hafta sonra bitiyor'
    }
  },
  {
    language: 'ur',
    textList: {
      t1: 'اس ہفتے کے بعد ختم ہوتا ہے'
    }
  }
]