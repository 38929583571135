// React libraries
import React from 'react';
// External libraries
// My links
import '../styles/sharedComponents.css';

const BigBofText = ({ com = false}) => {
  if (!com)
  {
    return (
      <i className="bigBofName"> 
        <i className="bigText">big</i>
        <i className="bofText">BOF</i>
      </i> // To add space after this compnent, add '&nbsp; ' in the jsx where you use it
    )
  }
  return (
    <div className="bigBofName">
      <section className="bigText">big</section>
      <section className="bofText">BOF</section>
      <div className="divComText"><section className="comText">{com ? '.com' : ''}</section></div>
    </div>
  )
}

export default BigBofText;
