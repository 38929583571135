import React from 'react';
// My links
import Header from './header/Header';
import Menu from './menu/Menu';
import Body from './body/Body';
import Footer from './footer/Footer';


document.addEventListener('contextmenu', event => event.preventDefault());

export const AppHeader = () => {
  return <Header />
}

export const AppMenu = () => {
  return <Menu />
}

export const AppBody = () => {
  return <Body />
}

export const AppFooter = () => {
  return <Footer />
}