import React from 'react';

const Link = ({ className, href, children }) => {
    const onClick = (event) => {
        if (event.metakey || event.ctrlKey) return;
        event.preventDefault();
        window.history.pushState({}, '', href);

        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);

        if(window.innerWidth <= 760){
            const barsState = document.querySelector('.bars');
            const barsDisplay = getComputedStyle(barsState).display;

            if (barsDisplay === 'block'){
            document.querySelector('.menuDiv').style.display = "block";
            document.querySelector('.mainBodyDiv').style.display = "none";
            document.querySelector('.bars').style.display = "none";
            document.querySelector('.times').style.display = "block";
            }
            else {
            document.querySelector('.menuDiv').style.display = "none";
            document.querySelector('.mainBodyDiv').style.display = "block";
            document.querySelector('.bars').style.display = "block";
            document.querySelector('.times').style.display = "none";
            }
        }
        else {
            document.querySelector('.menuDiv').style.display = "block";
            document.querySelector('.mainBodyDiv').style.display = "block";
            document.querySelector('.bars').style.display = "none";
            document.querySelector('.times').style.display = "none";
        }
    }
    return (
        <a onClick={onClick} href={href} className={className} >{children}</a>
    )
};

export default Link;