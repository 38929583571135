// React libraries
import React from 'react';

const Accordion = ({ title, hidden, onClick, children }) => {
  return (
    <div className="ui styled accordion">
      <div className={`${hidden ? '' : 'active '}title`} onClick={() => onClick()}>
        <i className="dropdown icon"></i>
        {title}
      </div>
      <div className={`${hidden ? '' : 'active'} content`}>
        <div className={`transition ${hidden ? 'hidden' : ''}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;