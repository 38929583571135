// React libraries
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
// External libraries
// My links
import Body from '../body/Body';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Link from '../components/Link';
import UseFetchText from '../hooks/useFetchText';
import {menuItems} from './MenuText';
import Languages from './menuComponents/Languages';
import './menu.css';
import useWindowResize from '../hooks/useWindowResize';
import Accordion from '../components/Accordion';

const Menu = () => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [selectedLanguage, setSelectedLanguage] = useState({label: "English", local: "English", value: "en"});
  const [policiesHidden, setPoliciesHidden] = useState(true);
  const [historyHidden, setHistoryHidden] = useState(true);

    useEffect(() => {
        const onLocationChange = () => {setCurrentPath(window.location.pathname);}

        window.addEventListener('popstate', onLocationChange);

        return () => {
            window.removeEventListener('popstate', onLocationChange);
            closeAllAccordions();
        }
    }, [currentPath]);

    const changeLanguage = (newLanguage) => {
      setSelectedLanguage(newLanguage);
    };

    useEffect(() => {
      if(selectedLanguage)
      {
        ReactDOM.render(<Header selectedLanguage={selectedLanguage} />, document.querySelector('.rootHeader'));
        ReactDOM.render(<Body selectedLanguage={selectedLanguage} />, document.querySelector('.rootBody'));
        ReactDOM.render(<Footer selectedLanguage={selectedLanguage} />, document.querySelector('.rootFooter'));
      }
    }, [selectedLanguage]);

    // The below code is to control behavior in a resizeable environment {
    const [width] = useWindowResize();
    const [initialWidth, setInitialWidth] = useState(width);
    if((width <= 760 && initialWidth > 760) || (width > 760 && initialWidth <= 760)){
      if(width <= 760){
        document.querySelector('.menuDiv').style.display = "none";
        document.querySelector('.mainBodyDiv').style.display = "block";
        document.querySelector('.bars').style.display = "block";
        document.querySelector('.times').style.display = "none";
      }
      else{
        document.querySelector('.menuDiv').style.display = "block";
        document.querySelector('.mainBodyDiv').style.display = "block";
        document.querySelector('.bars').style.display = "none";
        document.querySelector('.times').style.display = "none";
      }

      setInitialWidth(width);
    }
    //}

    const onPoliciesClick = () => {
      setPoliciesHidden(!policiesHidden);
    }

    const onHistoryClick = () => {
      setHistoryHidden(!historyHidden);
    }

    const closeAllAccordions = () => {
      setPoliciesHidden(true);
      setHistoryHidden(true);
    };
  
  return (
    <React.Fragment>
      <div className="ui vertical menu" >
        <i className="world icon">{selectedLanguage.label === 'English' ? 'Languages' : selectedLanguage.label}</i>
        <div className="divLanguagesList">
          <Languages changeLanguage={changeLanguage}/>
        </div>
        <Link href='/' className={`item${currentPath === '/' ? ' active' : ''}`}>{UseFetchText(selectedLanguage, menuItems, 't1')}</Link>
        <Link href='/login-register' className={`item${currentPath === '/login-register' ? ' active' : ''}`}>{UseFetchText(selectedLanguage, menuItems, 't2')}</Link>
        <Link href='/profile' className={`item${currentPath === '/profile' ? ' active' : ''}`}>{UseFetchText(selectedLanguage, menuItems, 't13')}</Link>
        <Link href='/current-tickets' className={`item${currentPath === '/current-tickets' ? ' active' : ''}`}>{UseFetchText(selectedLanguage, menuItems, 't3')}</Link>
        <Accordion 
          title={UseFetchText(selectedLanguage, menuItems, 't15')}
          hidden={historyHidden}
          onClick={onHistoryClick}
        >
          <Link href='/previous-tickets' className={`item${currentPath === '/previous-tickets' ? ' active' : ''}`}>{UseFetchText(selectedLanguage, menuItems, 't4')}</Link>
          <Link href='/previous-results' className={`item${currentPath === '/previous-results' ? ' active' : ''}`}>{UseFetchText(selectedLanguage, menuItems, 't14')}</Link>
          <Link href='/previous-winners' className={`item${currentPath === '/previous-winners' ? ' active' : ''}`}>{UseFetchText(selectedLanguage, menuItems, 't6')}</Link>
        </Accordion>
        <Link href='/friends' className={`item${currentPath === '/friends' ? ' active' : ''}`}>{UseFetchText(selectedLanguage, menuItems, 't5')}</Link>
        <Link href='/how-to-play' className={`item${currentPath === '/how-to-play' ? ' active' : ''}`}>{UseFetchText(selectedLanguage, menuItems, 't7')}</Link>
        <Accordion 
          title={UseFetchText(selectedLanguage, menuItems, 't12')}
          hidden={policiesHidden}
          onClick={onPoliciesClick}
        >
          <Link href='/privacy-policy' className={`item${currentPath === '/privacy-policy' ? ' active' : ''}`}>{UseFetchText(selectedLanguage, menuItems, 't11')}</Link>
          <Link href='/terms-and-conditions' className={`item${currentPath === '/terms-and-conditions' ? ' active' : ''}`}>{UseFetchText(selectedLanguage, menuItems, 't8')}</Link>
        </Accordion>
        <Link href='/contact-us' className={`item${currentPath === '/contact-us' ? ' active' : ''}`}>{UseFetchText(selectedLanguage, menuItems, 't9')}</Link>
        <div className="item">{UseFetchText(selectedLanguage, menuItems, 't10')}</div>
      </div>
    </React.Fragment>
  );
}

export default Menu;
