// React libraries
import React from 'react';
// External libraries
// My links
import Route from '../components/Route';
import Home from './bodyComponents/Home';
import LoginRegister from './bodyComponents/LoginRegister';
import Profile from './bodyComponents/Profile';
import CurrentTickets from './bodyComponents/CurrentTickets';
import PreviousTickets from './bodyComponents/PreviousTickets';
import PreviousResults from './bodyComponents/PreviousResults';
import PreviousWinners from './bodyComponents/PreviousWinners';
import Friends from './bodyComponents/Friends';
import HowToPlay from './bodyComponents/HowToPlay';
import PrivacyPolicy from './bodyComponents/PrivacyPolicy';
import TsAndCs from './bodyComponents/TsAndCs';
import ContactUs from './bodyComponents/ContactUs';

function Body({ selectedLanguage }) {
  return (
    <React.Fragment>
      <Route path="/">
        <Home selectedLanguage={selectedLanguage}/>
      </Route>
      <Route path="/login-register">
        <LoginRegister selectedLanguage={selectedLanguage}/>
      </Route>
      <Route path="/profile">
        <Profile selectedLanguage={selectedLanguage}/>
      </Route>
      <Route path="/current-tickets">
        <CurrentTickets selectedLanguage={selectedLanguage}/>
      </Route>
      <Route path="/previous-tickets">
        <PreviousTickets selectedLanguage={selectedLanguage}/>
      </Route>
      <Route path="/previous-results">
        <PreviousResults selectedLanguage={selectedLanguage}/>
      </Route>
      <Route path="/previous-winners">
        <PreviousWinners selectedLanguage={selectedLanguage}/>
      </Route>
      <Route path="/friends">
        <Friends selectedLanguage={selectedLanguage}/>
      </Route>
      <Route path="/how-to-play">
        <HowToPlay selectedLanguage={selectedLanguage}/>
      </Route>
      <Route path="/privacy-policy">
        <PrivacyPolicy selectedLanguage={selectedLanguage}/>
      </Route>
      <Route path="/terms-and-conditions">
        <TsAndCs selectedLanguage={selectedLanguage}/>
      </Route>
      <Route path="/contact-us">
        <ContactUs selectedLanguage={selectedLanguage}/>
      </Route>
    </React.Fragment>
  );
}

export default Body;