// React libraries
import React from 'react';
// External libraries
// My links
import {languageOptions} from '../../components/Constants';

const Languages = ({ changeLanguage, selectedLanguage }) => {
    if (!selectedLanguage) selectedLanguage = {label: "English", local: "English", value: "en"};

    const renderedLanguages = languageOptions.map(language => {
        return (
            <option key={language.value}>
                {language.local}
            </option>
        )
    });

    const updateSelection = ((e) => {

        for (var i=0; i < languageOptions.length; i++)
            if (languageOptions[i].local === e.target.value)
                changeLanguage(languageOptions[i]);
    });

    return (
        <React.Fragment>
            <select onChange={updateSelection} className="ui search dropdown">
                {renderedLanguages}
            </select>
        </React.Fragment>
    )
};

export default Languages;